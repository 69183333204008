
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="库位详情"
    :visible.sync="visible"
    width="900px"
    >

      <el-descriptions class="margin-top" :column="3"
      border>


            <el-descriptions-item label="ID">
            {{ row.id }}
            </el-descriptions-item>


            <el-descriptions-item label="仓库名称">
            {{ row.warehouseName }}
            </el-descriptions-item>


            <el-descriptions-item label="所属客户">
            {{ row.customerName }}
            </el-descriptions-item>

            <el-descriptions-item label="库位名称">
            {{ row.name }}
            </el-descriptions-item>

            <el-descriptions-item label="库位编码">
            {{ row.code }}
            </el-descriptions-item>

            <el-descriptions-item label="库位条码">
            {{ row.barcode }}
            </el-descriptions-item>


            <el-descriptions-item label="库位类型">
            {{ row.locationTypeName }}
            </el-descriptions-item>


            <el-descriptions-item label="库位温层">
            {{ row.locationTemperatureName }}
            </el-descriptions-item>


            <el-descriptions-item label="库位属性">
            {{ row.propertyName }}
            </el-descriptions-item>


            <el-descriptions-item label="库区">
            {{ row.areaName }}
            </el-descriptions-item>

            <el-descriptions-item label="巷道">
            {{ row.way }}
            </el-descriptions-item>

            <el-descriptions-item label="货架层">
            {{ row.layer }}
            </el-descriptions-item>

            <el-descriptions-item label="上架次序">
            {{ row.addedSequence }}
            </el-descriptions-item>

            <el-descriptions-item label="取货次序">
            {{ row.pickupSequence }}
            </el-descriptions-item>

            <el-descriptions-item label="最大重量（KG）">
            {{ row.maximumWeight }}
            </el-descriptions-item>

            <el-descriptions-item label="最大托盘数">
            {{ row.maximumPallet }}
            </el-descriptions-item>

            <el-descriptions-item label="长度（米）">
            {{ row.length }}
            </el-descriptions-item>

            <el-descriptions-item label="宽度（米）">
            {{ row.width }}
            </el-descriptions-item>

            <el-descriptions-item label="高度（米）">
            {{ row.height }}
            </el-descriptions-item>

            <el-descriptions-item label="最大体积（m³）">
            {{ row.maximumVolume }}
            </el-descriptions-item>

            <el-descriptions-item label="电子标签ID">
            {{ row.electronicTagId }}
            </el-descriptions-item>

            <el-descriptions-item label="储位组别">
            {{ row.binLocationGroup }}
            </el-descriptions-item>

            <el-descriptions-item label="最大批量">
            {{ row.maximumBatch }}
            </el-descriptions-item>

            <el-descriptions-item label="最小批量">
            {{ row.minimumBatch }}
            </el-descriptions-item>

            <el-descriptions-item label="动线">
            {{ row.line }}
            </el-descriptions-item>

            <el-descriptions-item label="存捡类型" v-if="enumMaps['WmsBinLocationPickTypeEnum']">
                 {{enumMaps['WmsBinLocationPickTypeEnum'][row.pickType]}}
            </el-descriptions-item>

            <el-descriptions-item label="出库口">
            {{ row.outboundPort }}
            </el-descriptions-item>

            <el-descriptions-item label="x坐标（cm）">
            {{ row.axisX }}
            </el-descriptions-item>

            <el-descriptions-item label="y坐标（cm）">
            {{ row.axisY }}
            </el-descriptions-item>

            <el-descriptions-item label="z坐标（cm）">
            {{ row.axisZ }}
            </el-descriptions-item>

            <el-descriptions-item label="备注">
            {{ row.comment }}
            </el-descriptions-item>

            <el-descriptions-item label="备注1">
            {{ row.comment1 }}
            </el-descriptions-item>

            <el-descriptions-item label="备注2">
            {{ row.comment2 }}
            </el-descriptions-item>

              <el-descriptions-item label="是否停用">
                {{row.disabled == 0 ? '否': '是'}}
              </el-descriptions-item>







      </el-descriptions>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { detailWmsBinLocation } from "@/api/wms/wmsBinLocation"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        visible: false,
        row: {
            id: '',
            warehouseId: '',
            warehouseName: '',
            customerId: '',
            customerName: '',
            name: '',
            code: '',
            barcode: '',
            locationTypeId: '',
            locationTypeName: '',
            locationTemperatureId: '',
            locationTemperatureName: '',
            propertyId: '',
            propertyName: '',
            areaId: '',
            areaName: '',
            way: '',
            layer: '',
            addedSequence: '',
            pickupSequence: '',
            maximumWeight: '',
            maximumPallet: '',
            length: '',
            width: '',
            height: '',
            maximumVolume: '',
            electronicTagId: '',
            binLocationGroup: '',
            maximumBatch: '',
            minimumBatch: '',
            line: '',
            pickType: '',
            outboundPort: '',
            axisX: '',
            axisY: '',
            axisZ: '',
            comment: '',
            comment1: '',
            comment2: '',
            disabled: '',
            createTime: '',
        },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsBinLocationPickTypeEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    detail() {
        const param = {
            id: this.row.id
        }
        detailWmsBinLocation(param).then(res=>{
          if(res.code === 200) {
            this.row = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    onOpen(row) {
        console.log("onOpen", row)
        this.visible = true
        this.row = row
        this.detail()
    },
    onClose() {
    },
    close() {
      this.visible = false
    },
  }
}

</script>
<style>
</style>
