<template>
  <div>
    <div class="contents">
      <submenu curbg=1></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <el-form-item label="仓库名称" style="margin-bottom:10px">

                  <el-input v-model="searchForm.warehouseName" clearable  style="width:190px"
                    placeholder="请输入仓库名称"></el-input>
                </el-form-item>

                <el-form-item label="所属客户" style="margin-bottom:10px">

                  <el-input v-model="searchForm.customerName" clearable  style="width:190px"
                    placeholder="请输入所属客户"></el-input>
                </el-form-item>

                <el-form-item label="库位名称" style="margin-bottom:10px">

                  <el-input v-model="searchForm.name" clearable  style="width:190px"
                    placeholder="请输入库位名称"></el-input>
                </el-form-item>

                <el-form-item label="库位编码" style="margin-bottom:10px">

                  <el-input v-model="searchForm.code" clearable  style="width:190px"
                    placeholder="请输入库位编码"></el-input>
                </el-form-item>

                <el-form-item label="库位温层" style="margin-bottom:10px">

                  <el-input v-model="searchForm.locationTemperatureName" clearable  style="width:190px"
                    placeholder="请输入库位温层"></el-input>
                </el-form-item>

                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>
              <el-button type="primary" class="tbBtn" icon="el-icon-plus" size="small" @click="handleAdd">添加</el-button>

            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>


        <vxe-column
            field="warehouseName"
            title="仓库名称"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.warehouseName}}
          </template>
        </vxe-column>
        <vxe-column
            field="customerName"
            title="所属客户"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.customerName}}
          </template>
        </vxe-column>
        <vxe-column
            field="name"
            title="库位名称"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.name}}
          </template>
        </vxe-column>
        <vxe-column
            field="code"
            title="库位编码"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.code}}
          </template>
        </vxe-column>
        <vxe-column
            field="barcode"
            title="库位条码"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.barcode}}
          </template>
        </vxe-column>
        <vxe-column
            field="locationTypeName"
            title="库位类型"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.locationTypeName}}
          </template>
        </vxe-column>
        <vxe-column
            field="locationTemperatureName"
            title="库位温层"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.locationTemperatureName}}
          </template>
        </vxe-column>
        <vxe-column
            field="propertyName"
            title="库位属性"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.propertyName}}
          </template>
        </vxe-column>
        <vxe-column
            field="areaName"
            title="库区"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.areaName}}
          </template>
        </vxe-column>
        <vxe-column
            field="way"
            title="巷道"
            min-width="50"
        >
          <template slot-scope="scope">
                {{scope.row.way}}
          </template>
        </vxe-column>
        <vxe-column
            field="layer"
            title="货架层"
            min-width="60"
        >
          <template slot-scope="scope">
                {{scope.row.layer}}
          </template>
        </vxe-column>
        <vxe-column
            field="addedSequence"
            title="上架次序"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.addedSequence}}
          </template>
        </vxe-column>
        <vxe-column
            field="pickupSequence"
            title="取货次序"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.pickupSequence}}
          </template>
        </vxe-column>
        <vxe-column
            field="maximumWeight"
            title="最大重量（KG）"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.maximumWeight}}
          </template>
        </vxe-column>
        <vxe-column
            field="maximumPallet"
            title="最大托盘数"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.maximumPallet}}
          </template>
        </vxe-column>
        <vxe-column
            field="length"
            title="长度（米）"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.length}}
          </template>
        </vxe-column>
        <vxe-column
            field="width"
            title="宽度（米）"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.width}}
          </template>
        </vxe-column>
        <vxe-column
            field="height"
            title="高度（米）"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.height}}
          </template>
        </vxe-column>
        <vxe-column
            field="maximumVolume"
            title="最大体积（m³）"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.maximumVolume}}
          </template>
        </vxe-column>
        <vxe-column
            field="electronicTagId"
            title="电子标签ID"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.electronicTagId}}
          </template>
        </vxe-column>
        <vxe-column
            field="submenuGroup"
            title="储位组别"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.submenuGroup}}
          </template>
        </vxe-column>
        <vxe-column
            field="maximumBatch"
            title="最大批量"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.maximumBatch}}
          </template>
        </vxe-column>
        <vxe-column
            field="minimumBatch"
            title="最小批量"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.minimumBatch}}
          </template>
        </vxe-column>
        <vxe-column
            field="line"
            title="动线"
            min-width="50"
        >
          <template slot-scope="scope">
                {{scope.row.line}}
          </template>
        </vxe-column>
        <vxe-column
            field="pickType"
            title="存捡类型"
            min-width="120"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsBinLocationPickTypeEnum'][scope.row.pickType]}}
          </template>
        </vxe-column>
        <vxe-column
            field="outboundPort"
            title="出库口"
            min-width="60"
        >
          <template slot-scope="scope">
                {{scope.row.outboundPort}}
          </template>
        </vxe-column>
        <vxe-column
            field="axisX"
            title="x坐标（cm）"
            min-width="140"
        >
          <template slot-scope="scope">
                {{scope.row.axisX}}
          </template>
        </vxe-column>
        <vxe-column
            field="axisY"
            title="y坐标（cm）"
            min-width="140"
        >
          <template slot-scope="scope">
                {{scope.row.axisY}}
          </template>
        </vxe-column>
        <vxe-column
            field="axisZ"
            title="z坐标（cm）"
            min-width="140"
        >
          <template slot-scope="scope">
                {{scope.row.axisZ}}
          </template>
        </vxe-column>
        <vxe-column
            field="comment"
            title="备注"
            min-width="50"
        >
          <template slot-scope="scope">
                {{scope.row.comment}}
          </template>
        </vxe-column>
        <vxe-column
            field="comment1"
            title="备注1"
            min-width="60"
        >
          <template slot-scope="scope">
                {{scope.row.comment1}}
          </template>
        </vxe-column>
        <vxe-column
            field="comment2"
            title="备注2"
            min-width="60"
        >
          <template slot-scope="scope">
                {{scope.row.comment2}}
          </template>
        </vxe-column>
        <vxe-column
            field="disabled"
            title="是否停用"
            min-width="80"
        >
          <template slot-scope="scope">
              {{scope.row.disabled == 0 ? '否': '是'}}
          </template>
        </vxe-column>

        <vxe-column title="操作" fixed="right" width="160">
            <template slot-scope="scope">
                  <el-button class="c-button" @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>

                  <el-button class="c-button" @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>

             </template>
        </vxe-column>

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <binlocation-add ref="wmsBinLocationAdd" @success="getTopSearch"></binlocation-add>
    <binlocation-detail ref="wmsBinLocationDetail" @success="getTopSearch"></binlocation-detail>
    <binlocation-edit ref="wmsBinLocationEdit" @success="getTopSearch"></binlocation-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import submenu from "@/components/submenu"
import binlocationAdd from "./wmsBinLocationAdd"
import binlocationDetail from "./wmsBinLocationDetail"
import binlocationEdit from "./wmsBinLocationEdit"

import { pageWmsBinLocation, detailWmsBinLocation, delWmsBinLocation, batchDeleteWmsBinLocation } from "@/api/wms/wmsBinLocation"
import { getEnums } from "@/api/common"
import { listWmsWarehouse } from "@/api/wms/wmsWarehouse"

import { listWmsCustomer } from "@/api/wms/wmsCustomer"

import { listWmsBinType } from "@/api/wms/wmsBinType"

import { listWmsTemperature } from "@/api/wms/wmsTemperature"

import { listWmsProperty } from "@/api/wms/wmsProperty"

import { listWmsArea } from "@/api/wms/wmsArea"


let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsBinLocation',
  components: { submenu, binlocationAdd, binlocationDetail, binlocationEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      enums: {
        WmsBinLocationPickTypeEnum: [],
      },
      enumMaps: {},
        optionsOfWmsWarehouse: [],

        optionsOfWmsCustomer: [],

        optionsOfWmsBinType: [],

        optionsOfWmsTemperature: [],

        optionsOfWmsProperty: [],

        optionsOfWmsArea: [],

      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要批量删除的记录")
        }
        this.$confirm('确定要删除这'+ this.multipleSelection.length +'条数据吗，一旦删除不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDeleteWmsBinLocation(param).then(res => {
                    if(res.code===200) {
                        this.$message.success(res.message)
                        this.getTopSearch()
                    } else {
                        this.$message.error(res.message)
                    }
                 })
        }).catch(err=>{})
    },
    handleAdd() {
      this.$refs.wmsBinLocationAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsBinLocationDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsBinLocationEdit.onOpen(row)
    },
    handleSelectionChange(val) {
        console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },

    listWmsWarehouse(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsWarehouse(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsWarehouse = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsCustomer(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsCustomer(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsCustomer = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsBinType(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsBinType(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsBinType = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsTemperature(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsTemperature(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsTemperature = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsProperty(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsProperty(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsProperty = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsArea(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsArea(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsArea = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      pageWmsBinLocation(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  created() {
    this.initEnums()
    this.listWmsWarehouse()

    this.listWmsCustomer()

    this.listWmsBinType()

    this.listWmsTemperature()

    this.listWmsProperty()

    this.listWmsArea()

    this.getTopSearch()
  },
  mounted(){
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.el-select {
    width: 190px;
}
.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.tbBtn {
  margin-right:10px;
}
.buttons {
}
</style>