
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="添加库位"
    :visible.sync="visible"
    width="1110px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        <el-col :span="8">
        <el-form-item label="仓库" prop="warehouseId">
                <el-select ref="warehouseId"
                                v-model="row.warehouseId"
                                prop="warehouseId"
                                placeholder="请选择仓库"
                                clearable
                                filterable
                                :filter-method="listWmsWarehouse"
                            >
                            <el-option
                                v-for="item in optionsOfWmsWarehouse"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="客户" prop="customerId">
                <el-select ref="customerId"
                                v-model="row.customerId"
                                prop="customerId"
                                placeholder="请选择客户"
                                clearable
                                filterable
                                :filter-method="listWmsCustomer"
                            >
                            <el-option
                                v-for="item in optionsOfWmsCustomer"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="库位名称" prop="name">
              <el-input v-model="row.name" placeholder="请输入库位名称" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="库位编码" prop="code">
              <el-input v-model="row.code" placeholder="请输入库位编码" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="库位条码" prop="barcode">
              <el-input v-model="row.barcode" placeholder="请输入库位条码" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="库位类型" prop="locationTypeId">
                <el-select ref="locationTypeId"
                                v-model="row.locationTypeId"
                                prop="locationTypeId"
                                placeholder="请选择库位类型"
                                clearable
                                filterable
                                :filter-method="listWmsBinType"
                            >
                            <el-option
                                v-for="item in optionsOfWmsBinType"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="库位温层" prop="locationTemperatureId">
                <el-select ref="locationTemperatureId"
                                v-model="row.locationTemperatureId"
                                prop="locationTemperatureId"
                                placeholder="请选择库位温层"
                                clearable
                                filterable
                                :filter-method="listWmsTemperature"
                            >
                            <el-option
                                v-for="item in optionsOfWmsTemperature"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="库位属性" prop="propertyId">
                <el-select ref="propertyId"
                                v-model="row.propertyId"
                                prop="propertyId"
                                placeholder="请选择库位属性"
                                clearable
                                filterable
                                :filter-method="listWmsProperty"
                            >
                            <el-option
                                v-for="item in optionsOfWmsProperty"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="库区" prop="areaId">
                <el-select ref="areaId"
                                v-model="row.areaId"
                                prop="areaId"
                                placeholder="请选择库区"
                                clearable
                                filterable
                                :filter-method="listWmsArea"
                            >
                            <el-option
                                v-for="item in optionsOfWmsArea"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="巷道" prop="way">
              <el-input v-model="row.way" placeholder="请输入巷道" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="货架层" prop="layer">
              <el-input v-model="row.layer" placeholder="请输入货架层" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="上架次序" prop="addedSequence">
              <el-input v-model="row.addedSequence" placeholder="请输入上架次序" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="取货次序" prop="pickupSequence">
              <el-input v-model="row.pickupSequence" placeholder="请输入取货次序" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="最大重量（KG）" prop="maximumWeight">
              <el-input-number v-model="row.maximumWeight"
                placeholder="请输入最大重量（KG）" clearable
                :step="0.001"
                :precision="3"
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="最大托盘数" prop="maximumPallet">
              <el-input-number v-model="row.maximumPallet"
                placeholder="请输入最大托盘数" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="长度（米）" prop="length">
              <el-input-number v-model="row.length"
                placeholder="请输入长度（米）" clearable
                :step="0.001"
                :precision="3"
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="宽度（米）" prop="width">
              <el-input-number v-model="row.width"
                placeholder="请输入宽度（米）" clearable
                :step="0.001"
                :precision="3"
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="高度（米）" prop="height">
              <el-input-number v-model="row.height"
                placeholder="请输入高度（米）" clearable
                :step="0.001"
                :precision="3"
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="最大体积（m³）" prop="maximumVolume">
              <el-input-number v-model="row.maximumVolume"
                placeholder="请输入最大体积（m³）" clearable
                :step="0.001"
                :precision="3"
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="电子标签ID" prop="electronicTagId">
              <el-input v-model="row.electronicTagId" placeholder="请输入电子标签ID" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="储位组别" prop="binLocationGroup">
              <el-input v-model="row.binLocationGroup" placeholder="请输入储位组别" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="最大批量" prop="maximumBatch">
              <el-input-number v-model="row.maximumBatch"
                placeholder="请输入最大批量" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="最小批量" prop="minimumBatch">
              <el-input-number v-model="row.minimumBatch"
                placeholder="请输入最小批量" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="动线" prop="line">
              <el-input v-model="row.line" placeholder="请输入动线" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="存捡类型" prop="pickType">
                <el-select v-model="row.pickType"
                clearable
                placeholder="请选择存捡类型">
                    <el-option
                          v-for="item in enums['WmsBinLocationPickTypeEnum']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="出库口" prop="outboundPort">
              <el-input v-model="row.outboundPort" placeholder="请输入出库口" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="x坐标（cm）" prop="axisX">
              <el-input-number v-model="row.axisX"
                placeholder="请输入x坐标（cm）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="y坐标（cm）" prop="axisY">
              <el-input-number v-model="row.axisY"
                placeholder="请输入y坐标（cm）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="z坐标（cm）" prop="axisZ">
              <el-input-number v-model="row.axisZ"
                placeholder="请输入z坐标（cm）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="备注" prop="comment">
              <el-input v-model="row.comment" placeholder="请输入备注" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">
        <el-form-item label="备注1" prop="comment1">
              <el-input v-model="row.comment1" placeholder="请输入备注1" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="备注2" prop="comment2">
              <el-input v-model="row.comment2" placeholder="请输入备注2" clearable
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>

        <el-col :span="8">
        <el-form-item label="是否停用" prop="disabled">
                <el-radio-group v-model="row.disabled" size="mini">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
               </el-radio-group>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { addWmsBinLocation, listWmsBinLocation } from "@/api/wms/wmsBinLocation"

import { listWmsWarehouse } from "@/api/wms/wmsWarehouse"

import { listWmsCustomer } from "@/api/wms/wmsCustomer"

import { listWmsBinType } from "@/api/wms/wmsBinType"

import { listWmsTemperature } from "@/api/wms/wmsTemperature"

import { listWmsProperty } from "@/api/wms/wmsProperty"

import { listWmsArea } from "@/api/wms/wmsArea"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            warehouseId: null,
            warehouseName: null,
            customerId: null,
            customerName: null,
            name: null,
            code: null,
            barcode: null,
            locationTypeId: null,
            locationTypeName: null,
            locationTemperatureId: null,
            locationTemperatureName: null,
            propertyId: null,
            propertyName: null,
            areaId: null,
            areaName: null,
            way: null,
            layer: null,
            addedSequence: null,
            pickupSequence: null,
            maximumWeight: null,
            maximumPallet: null,
            length: null,
            width: null,
            height: null,
            maximumVolume: null,
            electronicTagId: null,
            binLocationGroup: null,
            maximumBatch: null,
            minimumBatch: null,
            line: null,
            pickType: 0,
            outboundPort: null,
            axisX: null,
            axisY: null,
            axisZ: null,
            comment: null,
            comment1: null,
            comment2: null,
            disabled: 0,
      },
      rules: {
            warehouseId: [
                { required: true, message: '请输入仓库!'},
            ],
            name: [
                { required: true, message: '请输入库位名称!'},
            ],
            code: [
                { required: true, message: '请输入库位编码!'},
            ],
            barcode: [
                { required: true, message: '请输入库位条码!'},
            ],
            locationTypeId: [
                { required: true, message: '请输入库位类型!'},
            ],
            locationTemperatureId: [
                { required: true, message: '请输入库位温层!'},
            ],
            propertyId: [
                { required: true, message: '请输入库位属性!'},
            ],
            areaId: [
                { required: true, message: '请输入库区!'},
            ],
            way: [
                { required: true, message: '请输入巷道!'},
            ],
            layer: [
                { required: true, message: '请输入货架层!'},
            ],
            addedSequence: [
                { required: true, message: '请输入上架次序!'},
            ],
            pickupSequence: [
                { required: true, message: '请输入取货次序!'},
            ],
            pickType: [
                { required: true, message: '请输入存捡类型!'},
            ],
            disabled: [
                { required: true, message: '请输入是否停用!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsWarehouse: [],

        optionsOfWmsCustomer: [],

        optionsOfWmsBinType: [],

        optionsOfWmsTemperature: [],

        optionsOfWmsProperty: [],

        optionsOfWmsArea: [],

    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsWarehouse()
    this.listWmsCustomer()
    this.listWmsBinType()
    this.listWmsTemperature()
    this.listWmsProperty()
    this.listWmsArea()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsBinLocationPickTypeEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen() {
        this.visible = true
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },

    listWmsWarehouse(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsWarehouse(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsWarehouse = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsCustomer(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsCustomer(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsCustomer = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsBinType(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsBinType(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsBinType = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsTemperature(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsTemperature(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsTemperature = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsProperty(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsProperty(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsProperty = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsArea(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsArea(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsArea = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        addWmsBinLocation(param).then(res => {
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
</style>
